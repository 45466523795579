/** Oryginalnie to był CSS, dlatego taka sieka ;) **/

html {
  height: 100%;
}

body {
  color: #252629;
  height: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
  line-height: 36px;
  overflow-y: auto;
  background-color: #f0f3fa;
}

body.white {
  background-color: #FFFFFF;
}

.wrapper {
  padding: 30px 15px;
}

.template {
  display: none !important;
}

.error {
  display: none;
}

.display-none {
  display: none;
}

.button {
  box-shadow: 0 6px 16px rgba(92, 146, 255, 0.5);
  border-radius: 20px;
  background-color: #5c92ff;
  border-color: transparent !important;
  text-transform: uppercase;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  &.danger {
    box-shadow: 0 6px 16px rgba(248, 61, 61, 0.3);
    background-color: #f83d3d;
  }
}

.button:disabled {
  background-color: grey;
  box-shadow: 0 6px 16px grey;
}

.button img {
  margin-top: -3px;
}

.logo {
  width: 75px;
  height: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #495057 !important;
}

.input {
  position: relative;
  z-index: 3;
  background-color: transparent;
  //border: none;
  //border-bottom: 2px solid #5c92ff;
  //border-radius: 0;

  border-image: linear-gradient(to right, #0ba2e9 0%, #1dd369 100%) 1;
  border-radius: 3px; /* this doesn't work */
  border-width: 2px;
  border-style: solid;
  //padding: 17px;
  font-size: 12px;

  letter-spacing: 0.16px;

  @include media-breakpoint-down(sm) {
    color: #ffffff;
  }

}

.input-rev {
  position: relative;
  z-index: 3;
  background-color: transparent;
  //border: none;
  //border-bottom: 2px solid #5c92ff;
  //border-radius: 0;

  border-image: linear-gradient(to right, #1dd369 0%, #0ba2e9 100%) 1;
  border-radius: 3px; /* this doesn't work */
  border-width: 2px;
  border-style: solid;
  //padding: 17px;
  font-size: 12px;

  letter-spacing: 0.16px;

  @include media-breakpoint-down(sm) {
    color: #ffffff;
  }

}

.input-contact {
  border-radius: 6px;
  border: 1px solid #DBDDE0;
  background: #F5F9FC;
}

.input-contact-textarea {
  display: inline-flex;
  height: 161px;
  padding: 15px 59px 129px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #DBDDE0;
  background: #F5F9FC;
}

.header-contact {
  color: #0C4568;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 45px;
  padding-top: 20px;

  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    padding-top: 0;
  }
}

.header-dash {
  color: #0C4568;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 20px;

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
}

.deposit-info-header {
  margin-left: 0;
  padding-left: 0;
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
}

.deposit-info-details {
  border-radius: var(--spacing-12px, 12px);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(12, 69, 104, 0.11);
  width: 98%;
  margin-left: 0;
  margin-top: 15px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.deposit-info-details-tires {
  border-radius: var(--spacing-12px, 12px);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(12, 69, 104, 0.11);
  width: 98%;
  margin-left: 0;
  margin-top: 15px;
  background-image: url('../images/new/tires.png');
  background-repeat: no-repeat;
  min-height: 90px;
  padding-left: 65px;
  padding-top: 15px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.no-deposit-info-details {
  border-radius: var(--spacing-12px, 12px);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(12, 69, 104, 0.11);
  width: 98%;
  margin-left: 0;
  margin-top: 15px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.deposit-info-details-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 95%;
  min-height: 90px;
  padding-top: 3px;
  padding-left: 42px;
  background-image: url('../images/new/table_info.svg');
  background-repeat: no-repeat;
}

.deposit-info-details-content-company {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 95%;
  padding-top: 3px;
  padding-left: 12px;
  min-height: 150px;
}

.deposit-info-details-content-tires {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 95%;
  padding-top: 3px;
  padding-left: 0px;
  min-height: 150px;
}

.deposit-box {
  margin-left: 0;
  padding-left: 0;
  margin-right: 15px;



  @include media-breakpoint-down(sm) {
    margin-right: 10px;
    padding-right: 0;
  }
}

.relocked
{
  width: 98%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

}

.with-deposits
{
  margin-right: -15px;

  @include media-breakpoint-down(sm) {
    margin-right: -30px;
  }
}

.deposit-box-tires {
  margin-left: 0;
  padding-left: 0;
  margin-right: 15px;
  padding-right: 0;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea.input {
  height: 170px;
  @media screen and (min-width: 768px) {
    height: 300px;
  }
}

.form-group label {
  letter-spacing: 0.12px;
  font-size: 10px;
  color: #97a9cc;
  padding-bottom: 0;
  margin-top: -35px;
  margin-left: 10px;
  display: none;
}

.hyperlink {
  font-weight: bold;
  color: #0BA4E3 !important;
  letter-spacing: 0.35px;

}

.hyperlink.smaller {
  font-size: 14px;
}

.smaller-text {
  font-size: 12px;
}

.huge-text {
  font-size: 36px;
}

.padding-0-30 {
  padding: 0 30px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-18 {
  line-height: 18px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-13 {
  font-size: 13px;
}

.text-dark-green {
  color: #1CD368;
}

.text-green {
  color: #84e407;
}

.text-orange {
  color: #fbba00;
}

.text-grey {
  color: #97a9cc;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.white-background-row {
  background-color: #ffffff;
  margin-top: -15px;
  padding-top: 15px;
}

.panel {
  border: 1px solid #e6ebf5;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 0 -7px 15px -7px;
}

.login-image {
  background-image: url('../images/new/tlo.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: #ebf0fa;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.login-image div {
  width: 70%;
  margin-top: 130px;
  margin-left: 220px;
  @include media-breakpoint-only(md) {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }
}

.login-image h1 {
  font-size: 57px;
  margin-top: 70vh;
  letter-spacing: -1.18px;
}

.login-image h1 > span {
  background-image: linear-gradient(45deg, #0ba2e9, #1dd369);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.with-tires,
.without-tires,
.without-deposits {
  img {
    margin: 0 15px 0 0;
  }

  p {
    strong {
      color: #6e7c99;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      display: block;
    }

    color: #9b9fa8;
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    width: 100%;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }
}

.panel {
  &.without-tires,
  &.without-deposits {
    background: white url("../images/Placeholder_ico.png") no-repeat bottom center;
    padding-bottom: 150px;
  }
}

.panel-content {
  //padding: 20px;
  padding: 0;
  margin: 0;
  background: none;
}

.panel-content.smaller {
  padding: 10px 20px;
  font-size: 12px;
  line-height: 15px;
}

.panel-buttons {
  width: 100%;
  border-top: 1px solid #e6ebf5;
}

.panel-buttons .btn {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-right: 1px solid #e6ebf5;
  width: 50%;
  height: 40px;
  padding: 9px 9px;
  cursor: pointer;
  color: #5c92ff !important;
}

.panel-buttons .btn:disabled {
  color: #ced3db !important;
}

.panel-buttons .btn.disabled {
  color: #ced3db !important;
}

.panel-buttons .btn:last-child {
  border-right: none;
}

.main-container {
  min-height: 100%;
  overflow-x: hidden;
}

footer {
  width: 100%;
  height: 75px;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px rgba(110, 124, 153, 0.1);
}

.footer-icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center;
  padding: 15px 0;
  color: #6e7c99 !important;
  font-size: 11px;
}

.footer-icon.active {
  color: #0C4568 !important;
  background-image: url('../images/new/menu_mobile_active.svg');
  width: 68px;
  height: 66px;
  position: absolute;
  margin-top: -34px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
}

.footer-icon div.info-text {
  margin-top: -10px;
}

.footer-icon.active div.info-text {
  margin-top: 20px;
}


.footer-icon div {
  float: none;
  display: block;
  margin: auto;
}


.ul-pointed {
  list-style-type: circle;
}

.menu-button div.car {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/car_on.svg')
}

.menu-button div.dash {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/dash_on.svg')
}

.menu-button div.services {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/serwisy_on.svg')
}

.menu-button div.contact {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/zlecenia_on.svg')
}

.menu-button.active div.car {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/car.svg')
}

.menu-button.active div.dash {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/dash.svg')
}

.menu-button.active div.services {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/serwisy.svg')
}

.menu-button.active div.contact {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/zlecenia.svg')
}

.footer-icon div.car {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/car_on.svg')
}

.footer-icon div.dash {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/dash_on.svg')
}

.footer-icon div.services {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/serwisy_on.svg')
}

.footer-icon div.contact {
  width: 40px;
  height: 40px;
  margin-top: -8px;
  background-image: url('../images/new/zlecenia_on.svg')
}

.footer-icon.active div.car {
  width: 40px;
  height: 40px;
  margin-top: -4px;
  background-image: url('../images/new/car.svg')
}

.footer-icon.active div.dash {
  width: 40px;
  height: 40px;
  margin-top: -4px;
  background-image: url('../images/new/dash.svg')
}

.footer-icon.active div.services {
  width: 40px;
  height: 40px;
  margin-top: -4px;
  background-image: url('../images/new/serwisy.svg')
}

.footer-icon.active div.contact {
  width: 40px;
  height: 40px;
  margin-top: -4px;
  background-image: url('../images/new/zlecenia.svg')
}


.icon {
  width: 25px;
  height: 25px;
}

.icon-settings {
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../images/logout.png');
  background-size: contain;
}

.icon-settings2 {
  float: right;
  color: #9EA1A8;
}

.close {
  width: 17px;
  height: 17px;
  background-image: url('../images/Cross.png');
  float: right;
  cursor: pointer;
}

.closeMenu {
  width: 24px;
  height: 24px;
  background-image: url('../images/new/chevron-left.png');
  float: left;
  cursor: pointer;
  margin-top: 4px;
}

.modal-background {
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 246, 250, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: none;
  @media (min-width: 480px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.modal {
  width: 100%;
  height: auto;
  max-height: calc(100% - 60px);
  overflow-y: auto;
  padding: 30px;
  box-shadow: 0 6px 16px rgba(92, 146, 255, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 101;
  position: relative;
  display: inherit;
  opacity: 1;
  max-width: 480px;
  margin: 75px auto;
}

.modal img {
  margin: 0 auto;
  display: block;
}

.modal.modal2 {
  margin-top: 0;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.modal-background.modal2 {
  padding: 0;
  background-color: #f5f6fa;
}

.twitter-typeahead {
  width: 100%;
}

.tt-menu {
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #e6ebf5;
  background-color: #ffffff;
}

.tt-suggestion {
  padding: 0 15px;
}

.tt-suggestion.tt-cursor {
  background-color: #e6ebf5;
}

/* Login */

.login-heading {
  margin-top: 35px;
  font-size: 29px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.login-subheading {
  font-size: 14px;
  font-weight: 300;
  color: #353c4a;
}

.login-bottom {
  margin-top: 0px;
  margin-left: 10%;
  margin-right: 10%;
}

.login-form {
  //margin-top: 40px;
  /*min-height: 135px;
  max-width: 100%;*/
  //height: 220px;
  margin-left: 10%;
  margin-right: 10%;
}

.help-info {
  font-size: 13px;
  color: #929497;
}

.login-form .form-group {
  margin-top: 50px;
  position: relative;

  label {
    transition: transform .3s ease-in-out;
    transform-origin: left bottom;
    display: block;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    letter-spacing: 0.16px;
    //font-size: 1rem;
    color: #97a9cc;
    width: 100%;
    display: block;
    width: 100%;
    //height: calc(2.25rem + 2px);
    //padding: 0.375rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    bottom: 0.375rem;
    left: 0.75rem;
    z-index: 0;
    transform: scale(.75) translateY(-3rem);

    &.placeholder {
      transform: scale(1) translateY(0);
      //z-index: -1;
      @include media-breakpoint-down(sm) {
        color: #ffffff;
      }

    }

    @include media-breakpoint-down(sm) {
      color: #ffffff;
    }

  }

  .form-control:focus {
    background-color: transparent;
    @include media-breakpoint-down(sm) {
      color: #ffffff;
    }
  }
}

.login-button {
  margin-top: 15px;
  border-radius: 5px;
  background-color: #1bce76; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #0aa2e6, #1bce76);
  padding: 10px;
}

.danger-button {
  margin-top: 15px;
  border-radius: 5px;
  background-color: #ff0000; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #ff0000, #d30202);
  padding: 10px;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: inherit;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5c92ff;
  background-color: #5c92ff;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5c92ff;
  background-color: #5c92ff;
}

.custom-control {
  padding-left: 0
}

.login-form .form-group label.custom-control-label {
  font-size: 1.25rem;
  line-height: 2rem;
  padding-left: 2rem;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 2rem;
  height: 2rem;
  top: 0;
  left: -1rem;
}

.step2, .step3, .step-animated2, .step-animated3, .step4, .step-animated4 {
  display: none;
}

.step1 {
  display: block;
}

.sf-toolbar {
  display: none;
}


/* Dash */

.header {
  margin-bottom: 15px;
  position: relative;
  @media (max-width: 990px) {
    margin: 0 -20px 15px;
    padding: 0 20px;
  }
}

header2 {
  position: relative;
}

.header-background {
  width: 100%;
}

.header-inner {
  color: #ced9f0;
  position: relative;
  padding: 20px 20px 10px 20px;
  margin: 0 -15px;
  min-height: 185px;
}

.wheel-watermark {
  width: 185px;
  height: 106px;
  position: absolute;
  bottom: 0;
  right: -15px;
  background-image: url('../images/BigWheelWatermark.png');
}

.contact-watermark {
  width: 140px;
  height: 117px;
  position: absolute;
  bottom: 0;
  right: -15px;
  background-image: url('../images/Contact_ico.png');
}

.tiny-logo {
  width: 70px;
  height: 21px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.dash-title {
  color: #fbba00;
  font-size: 22px;
  max-width: 450px;
}

.dash-title .smaller, .dash-title.smaller {
  font-size: 18px;
}

.header-small-text {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  color: #97a9cc;
  display: block;
  margin-top: -10px;
}

.header-regular-white-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  display: block;
}

.dash-deposit-status {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.dash-deposit-status-small {
  font-size: 10px;
  color: #97a9cc;
  font-weight: 400;
}

.dash-text-grey {
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -0.13px;
  color: #6e7c99;
  font-size: 13px;

  &-light {
    color: #ced9f0;
  }
}

.dash-appointment-header {
  font-size: 16px;
  font-weight: 500;
  color: #FAB900;
}

.dash-appointment-header-date {
  font-size: 14px;
  color: #0C4568;
}

.carefleet-logo {
  width: 143px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  float: right;
  display: inline;
  @include ellipsis;
  line-height: 24px;
}

#map-overview {
  min-width: 95%;
  height: 150px;
  float: right;
  border-radius: 20px;
  overflow: hidden;
  width: calc(50%);
  margin-top: 15px;
  margin-right: 20px;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
    width: 50%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  margin: 10px auto;
  background-image: url('../images/loading-v2.gif');
  background-size: contain;
  display: none;
}

.loaded {
  display: none;
}


/* Services */

#map {
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
  border-radius: 20px;
  @media screen and (min-width: 768px) {
    height: 500px;
  }
}

.large-separator {
  height: 30px;
  width: 1px;
  background-color: #ced9f0;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.score-container {
  margin-top: 10px;
}

#search {

}

.open-container {
  margin-left: 10px;
}

.search-wrapper {
  position: absolute;
  padding: 20px 20px 0 20px;
  z-index: 1;
  width: 100%;
}

.search-header {
  background-image: none;
  width: calc(100% + 40px);
  height: 45px;
  color: #353c4a;
  font-size: 18px;
  font-weight: 500;
  line-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  margin: -20px -20px 5px -20px;
  @include ellipsis;
}

.men-header {
  background-image: none;
  width: calc(100% + 40px);
  height: 45px;
  color: #353c4a;
  font-size: 18px;
  font-weight: 500;
  line-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  margin: -20px -20px 5px -20px;
  @include ellipsis;
}

.cal-header {
  background-image: none;
  width: calc(100% + 40px);
  height: 45px;
  color: #353c4a;
  font-size: 18px;
  font-weight: 500;
  line-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  @include ellipsis;
}

.search-header img {
  float: left;
  margin-top: 16px;
  margin-right: 10px;
}

.main-button {
  width: auto;
  position: fixed;
  bottom: 90px;
  right: 15px;
  @media screen and (min-width: 768px) {
    right: calc(50% - 330px);
  }
  @media screen and (min-width: 992px) {
    right: calc(50% - 450px);
  }
  @media screen and (min-width: 1200px) {
    right: calc(50% - 540px);
  }

}

.deposited-tire {
  width: 100%;

  .td {
    @media screen and (max-width: 480px) {
      .dash-text-grey {
        font-size: 11px;
      }
      img {
        margin: 0 !important;
      }
    }
  }
}

.uploaded-file-label {
  color: #5c92ff;
  @include ellipsis();
}

.uploaded-file-size {
  color: #97a9cc;
  font-size: 13px;
  float: right;
}

.remove-file {
  padding: 5px;
}


/* Car */

.background-embeded-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #97a9cc;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    padding-left: 20px;
  }
}

.history-bullet {
  top: 16px;
}

.history-relocation-names {
  width: calc(100% - 8rem);

  .text-overflow-ellipsis {
    min-width: 1rem;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}

.history-relocation {
  margin-bottom: 0;
  margin-top: -10px;
}

.history-relocation td {
  width: 45%;
}

.history-relocation td.middle {
  width: 10%;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}

.history-workshop-from-name,
.history-workshop-to-name {

}

#history-wrapper {
  .td {
    width: calc(50% - 1.125rem)
  }
}

/* Calendar */

.calendar-btn {
  display: inline-block;
  width: 9ex;
  height: 36px;
  margin: 3px;
  font-weight: 400;
  color: #0C4568;
  font-size: 14px;
  border-radius: 4px;
  border-color: #6e6f72;
  background: none;
}

.calendar-btn:disabled {
  background: none;
}

.calendar-btn.selected {
  background: linear-gradient(to bottom, #0D486C 0%, #1885C9 100%);
  color: #ffffff;
}

.calendar-label {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.59px;
  color: #0C4568;
  display: block;
}

.calendar-card {
  background-color: #ffffff;
  width: 13.5%;
  height: 80px;
  margin: 1px;
  text-align: center;
  display: inline-block;
  padding-top: 5px;
  line-height: 25px;
  cursor: pointer;
  border-radius: 10px;
}

.calendar-card-day-name {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  color: #0C4568;
  padding-top: 5px;
}

.calendar-card-day-name.disabled {
  color: #D9D9D9;
}

.calendar-card-day-nr {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  color: #0C4568;
  padding-top: 5px;
}

.calendar-card-day-nr.disabled {
  color: #D9D9D9;
}

.calendar-card-dash {
  background-color: #1CD368;
  border-radius: 2px;
  height: 2px;
  width: 12px;
  margin: 8px auto;
  display: block;
}

.calendar-card-dash.disabled {
  background-color: #D9D9D9;
}

.calendar-card.active {
  background: linear-gradient(to bottom, #0D486C 0%, #1885C9 100%);
  .calendar-card-day-nr
  {
    color: #ffffff;
  }
  .calendar-card-day-name {
    color: #1CD368;
  }
}

#calendar-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#calendar-header span, #calendar-header a {
  padding: 10px 30px;
}

#calendar-cards-container {
  margin-bottom: 5px;
  position: relative;
  text-align: center;

  &.before-right {
    transform: translateX(0);
    width: 200%;

    &.right {
      transform: translateX(-50%);
    }
  }

  &.before-left {
    width: 200%;
    margin-left: -100%;

    &.left {
      transform: translateX(50%);
    }
  }

  &.animate {
    transition: transform .5s ease-in-out;
  }

  .to-remove {
    width: 50%;
    display: inline-block;
    text-align: center;
  }

  .to-replace {
    width: 50%;
    display: inline-block;
    text-align: center;
  }
}

.calendar-cards-wrapper {
  overflow: hidden;
  height: 80px;
}


/* Menu */

.menu {
  width: 320px;
  height: 100%;
  box-shadow: 2px 0 6px rgba(110, 124, 153, 0.1);
  background-color: #ffffff;
  padding: 25px;
  line-height: 18px;
  float: left;
}

.menu .logo {
  margin-bottom: 25px;
}

.menu-button-container {
  margin: 25px 0;
}

.menu-button {
  width: calc(100% + 50px);
  display: block;
  padding: 15px 25px;
  margin-left: -25px;
}

.menu-button div {
  display: inline-block;
  position: absolute;
}

.menu-button.active {
  background: linear-gradient(to right, #0ba2e9 0%, #1dd369 100%);
}

.menu-button span {
  font-size: 14px;
  font-weight: 400;
  color: #6e7c99 !important;
  padding-left: 45px;
  padding-top: 5px;
  display: inline-block;
}

.menu-button.active span {
  color: #ffffff !important;
}

.menu-separator {
  width: 100%;
  height: 1px;
  background-color: #ebf0fa;
  margin-top: 5px;
}

#menu-day-name,
#menu-year {
  color: #97a9cc;
  letter-spacing: -0.16px;
  font-size: 16px;
  font-weight: 300;
  padding: 15px 0;
}

#menu-date {
  font-weight: 300;
  color: #495366;
  letter-spacing: -0.36px;
  font-size: 36px;
}

.menu-bottom-logo {
  width: 270px;
  height: 60px;
  position: absolute;
  bottom: 20px;
  /*background-image: url('../images/Sample_Fleet_logo.png');*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


/* RWD */

@media screen and (max-width: 920px) {
  .menu {
    display: none;
  }
  .header.simple {
    padding: 0;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 920px) {
  footer,
  .footer-spacer,
  .icon-settings {
    display: none;
  }
  .wheel-watermark, .contact-watermark {
    right: 0;
  }
  .header,
  .header2,
  .background-embeded-header {
    margin-left: 0;
  }
  .header-background {
    margin: 0 15px;
  }
  body {
    background-color: #fafafa;
  }
  .main-container {
    position: relative;
    padding: 0;
    max-width: 690px;
    padding-left: 320px;
    box-shadow: 0 0 2px rgba(110, 124, 153, 0.2);
    background-color: #ffffff;

    & > section > .row,
    & > .row {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: calc(50% - 345px);
    //transform: translateX(-100%);
  }
  .header {
    margin-left: 0;
  }
}

@media screen and (min-width: 920px) {
  .main-container {
    max-width: 930px;
  }
  .menu {
    left: calc(50% - 465px);
    //transform: translateX(-100%);
  }
}

@media screen and (min-width: 1200px) {
  .main-container {
    max-width: 1110px;
  }
  .menu {
    left: calc(50% - 555px);
    //transform: translateX(-100%);
  }
}


/* Relocation & Booking */

#relocation-modal img {
  /*width: 40px;*/
  margin-right: 20px;
  margin-left: 20px;
}

#relocation-modal .panel {
  width: calc(100% - 80px);
}

.line {
  margin-left: 30px;
  margin-right: 30px;
  height: 40px;
}

.line-solid {
  border-left: 1px solid #ced9f0;
}

.line-dashed {
  border-left: 1px dashed #ced9f0;
}

#calendar-book-execute-button,
#book-execute-button {
  margin: 30px auto;
  max-width: 258px;
  display: block;
}

#book-details-button {
  max-width: 258px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#confirmation-modal {
  z-index: 10000;
}

#calendar-confirm-modal {
  .button {
    font-size: 14px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.form-control:focus {
  box-shadow: none;
}

.contactform .form-group label {
  line-height: 1;
  display: block;
  margin: 0 0 0 10px;
}

.contactform textarea {
  box-shadow: 2px 2px 3px 1px #ddd;
  margin-top: 5px;
}

.r-180 {
  transform-origin: center center;
  transform: rotate(180deg);
}

#goto-my-location {
  @include absolute($bottom: 15px, $right: 0);
  display: none;
  @media screen and (min-width: 768px) {
    bottom: 120px;
    right: 3px;
  }
}

#main-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('../images/loading-v2.gif') no-repeat center center rgba(255, 255, 255, .95);
  background-size: 100px 100px;
  display: none;
  z-index: 999999999999999999;

}

#history-wrapper {
  .panel {
    margin-bottom: 10px;
    margin-left: 1px;
    margin-right: 18px;
    border-radius: 10px;
    margin-top: 10px;
    background: #ffffff;
  }
}

.rating-container {
  line-height: 13px;

  .dash-text-grey {
    line-height: 13px;
  }

  em {
    display: inline-block;
    background: url("../images/star0.svg") no-repeat center center;
    background-size: contain;
    width: 13px;
    height: 13px;
    margin: 5px 0 5px 0;
  }

  &--large {
    em {
      width: 18px;
      height: 18px;
    }
  }

  .size--0 {
    &--25 {
      em:nth-child(1) {
        background-image: url("../images/star0.25.svg");
      }
    }

    &--50 {
      em:nth-child(1) {
        background-image: url("../images/star0.5.svg");
      }
    }

    &--75 {
      em:nth-child(1) {
        background-image: url("../images/star0.75.svg");
      }
    }
  }

  .size--1 {
    em {
      &:nth-child(1) {
        background-image: url("../images/star1.svg");
      }
    }

    &--25 {
      em:nth-child(2) {
        background-image: url("../images/star0.25.svg");
      }
    }

    &--50 {
      em:nth-child(2) {
        background-image: url("../images/star0.5.svg");
      }
    }

    &--75 {
      em:nth-child(2) {
        background-image: url("../images/star0.75.svg");
      }
    }
  }

  .size--2 {
    em {
      &:nth-child(1) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(2) {
        background-image: url("../images/star1.svg");
      }
    }

    &--25 {
      em:nth-child(3) {
        background-image: url("../images/star0.25.svg");
      }
    }

    &--50 {
      em:nth-child(3) {
        background-image: url("../images/star0.5.svg");
      }
    }

    &--75 {
      em:nth-child(3) {
        background-image: url("../images/star0.75.svg");
      }
    }
  }

  .size--3 {
    em {
      &:nth-child(1) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(2) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(3) {
        background-image: url("../images/star1.svg");
      }
    }

    &--25 {
      em:nth-child(4) {
        background-image: url("../images/star0.25.svg");
      }
    }

    &--50 {
      em:nth-child(4) {
        background-image: url("../images/star0.5.svg");
      }
    }

    &--75 {
      em:nth-child(4) {
        background-image: url("../images/star0.75.svg");
      }
    }
  }

  .size--4 {
    em {
      &:nth-child(1) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(2) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(3) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(4) {
        background-image: url("../images/star1.svg");
      }
    }

    &--25 {
      em:nth-child(5) {
        background-image: url("../images/star0.25.svg");
      }
    }

    &--50 {
      em:nth-child(5) {
        background-image: url("../images/star0.5.svg");
      }
    }

    &--75 {
      em:nth-child(5) {
        background-image: url("../images/star0.75.svg");
      }
    }
  }

  .size--5 {
    em {
      &:nth-child(1) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(2) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(3) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(4) {
        background-image: url("../images/star1.svg");
      }

      &:nth-child(5) {
        background-image: url("../images/star1.svg");
      }
    }
  }
}

.reservation-info {
  padding-top: 15px;
  padding-right: 15px;
}

#search {
  padding-left: 42px;
}

.btn-search {
  position: absolute;
  bottom: 5px;
  left: 26px;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  box-shadow: none !important;

  img {
    width: 28px;
    height: auto;
  }
}

#car-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

strong {
  font-weight: 500;
}

.service {
  &-online {
    span {
      color: #5c92ff;
    }
  }

  &-offline {
    span {
      color: #6e7c99;
    }
  }
}

#call-pinned-button {
  color: white;
}

.hover.panel {
  background-color: #f0f2f5;
}

.font-500 {
  font-weight: 500;
}

.settings {
  z-index: 3;
}

#submit-auth-code,
#request-auth-code {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
}

.no-position .distance {
  display: none !important;
}

#enable-geolocation {
  display: none;
}

.no-position #enable-geolocation {
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  background-color: white;
  border: 1px solid #e6ebf5;
  background-color: #ffffff;
  border-radius: 4px;
  bottom: 37px;
  line-height: 1.25;
  padding: 10px 20px;
  @media screen and (min-width: 768px) {
    right: 65px;
  }
  text-decoration: none;

}

.toast-message {
  line-height: 1.25;
}


.qr-code {
  display: none;
}

.active .qr-code {
  display: block;
  position: absolute;
  bottom: -38px;
  left: 20px;
  background: url(../images/qr-bg.png) center center no-repeat transparent;
  background-size: contain;
  width: 232px;
  height: 229px;
  padding: 31px 39px 41px 35px;

  img {
    width: 100%;
    height: auto;
  }
}

#vin-helper {
  text-decoration: none;
  right: 0.35rem;
  bottom: 0.05rem;

  img {
    &:last-child {
      display: none;
    }
  }

  &.active {
    img {
      &:last-child {
        display: block;
        position: absolute;
        right: 0.5rem;
        bottom: -3rem;
      }
    }
  }
}


.ios-prompt {
  background-color: #fcfcfc;
  border: 1px solid #aaa;
  display: none;
  padding: 0.8rem 1rem 0 0.5rem;
  text-decoration: none;
  font-size: 14px;
  color: #353c4a;
  position: fixed;
  margin: 0 auto 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 0;
  transition-delay: 2s;
  transition: all ease-in-out .5s;
  transform: translateX(-100vh);

  &.animate {
    transform: translateX(0);
  }
}

.rate_form {
  select {
    display: none;
  }

  label {
    letter-spacing: 0.12px;
    font-size: 12px;
    color: #97a9cc;
    padding-bottom: 0;
    display: block;
    line-height: 18px;
  }

  .form-group.d-flex label {
    min-width: 5rem;
    margin-bottom: 0;
    display: block;
    margin: 0;
    line-height: 18px;
  }

  input[type=text],
  textarea {
    border: 1px solid rgba(#97a9cc, .5);
    color: #97a9cc;
    width: 100%;
    padding: 10px;
    line-height: 18px;
    font-size: 14px;
  }

  .ratings {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    direction: rtl;
    text-align: left;
    height: 18px;
    line-height: 18px;
  }

  .star {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease;
    background: url('../images/star0.svg') no-repeat center center transparent;
    background-size: contain;
  }

  .star:hover,
  .star.selected,
  .star:hover ~ .star,
  .star.selected ~ .star {
    transition: all 0.8s ease;
    background-image: url('../images/star1.svg');
  }

  .btn {
    width: auto;
  }
}

[data-component=rejection] {
  display: none !important;
}

[data-rejection-container] [data-component=rejection] {
  display: block !important;
}

#services-container {

}

.logo-new {
  max-height: 42px;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.logo-new-mobile {
  display: none;
  max-height: 42px;
  @include media-breakpoint-down(sm) {
    display: inline-block;
  }
}

.header-logo-new {
  margin-top: 20px;
  font-size: 16px;
  color: #6E7075;
  margin-bottom: 60px;
}

#main-container {
  @include media-breakpoint-down(sm) {
    background-image: url('../images/new/iphone_tlo.png');
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
}


div#main-container.logged-in {

  background: linear-gradient(to right, #c7dcef 0%, #f5f8fd 100%);

  @include media-breakpoint-down(sm) {
    padding-left: 15px;
    padding-right: 15px;
  }




  //background-image: url('../images/new/tlo_back.jpg');
  //background-size: auto;
  //background-repeat: no-repeat;
}

.content-panel {
  margin-top: 15px;
  margin-left: 35px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.car-detail {
  background-image: linear-gradient(to bottom, #19597d 0%, #17679f 100%);
  border-radius: 10px; /* this doesn't work */
  border-width: 10px;
  margin: 0;
  padding: 0;
  height: 252px;
  padding-top: 160px;

  @include media-breakpoint-down(sm) {
    height: 150px;
    padding-top: 65px;
  }
}

.car-detail-info {
  color: #FBB900;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  margin-left: 15px;
  text-transform: uppercase;
}

.car-detail-reg-nr {
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  margin-top: 20px;
  margin-left: 15px;
}

.car-detail-vin {
  color: #D9D9D9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  margin-top: 40px;
  margin-left: 15px;
}

.menu-dash {
  margin-top: 155px;

  @include media-breakpoint-down(sm) {
    margin-top: 60px;
  }

  @media screen and (max-width: 574px) {
    margin-top: 10px;
  }

  // menu-dash
}

.menu-dash-info {
  margin-bottom: 15px;
}

.btn-dash {
  border: none;
  background: none;
  color: #0C4568;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  margin-left: auto;
  margin-right: 25px;

  @media screen and (max-width: 455px) {
    font-size: 10px;
    margin-right: 0;
  }
}

.search-service-img
{
  margin-right: 18px;

  @media screen and (max-width: 355px) {
    max-width: 10px;
  }

  @media (min-width: 356px) and (max-width: 455px) {
    max-width: 20px;
  }
}

.reservation-img
{
  margin-right: 10px;

  @media screen and (max-width: 355px) {
    max-width: 10px;
  }

  @media (min-width: 356px) and (max-width: 455px) {
    max-width: 20px;
  }
}

.btn-dash:hover {
  text-decoration-line: underline;
}

.btn-dash.disabled {
  cursor: default;
  color: #878484;
}

.btn-dash.disabled:hover {
  text-decoration-line: none;
}

#deposit-status, #car-workshop-status {
  position: absolute;
}

.workshop-info-div {
  position: absolute;
  margin-top: 20px;
  color: #9EA1A8;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
}

#deposit-content-wrapper, #car-deposit-content-wrapper {
  position: absolute;
  margin-top: 45px;
  margin-left: -39px;
  color: #1C1C1C;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#tires-deposit-content-wrapper {
  //position: absolute;
  margin-top: 22px;
  margin-left: -39px;
  color: #1C1C1C;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#workshop-name {
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #1CD368;
}

.car-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  text-align: left;
  display: block;
}

.car-reg-nr {
  font-size: 26px;
  font-weight: 400;
  line-height: 30.81px;
  color: #0AA2E7;
  display: block;
}

.service-list-company-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.59px;
  color: #1CD368;
}

.service-list-company-addr {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.22px;
  text-align: left;
  color: #9EA1A8;
}

.service-list-km {
  color: #ffffff;
  background: #0867b5;
  font-size: 10px;
  font-weight: 500;
  padding: 3px;
  border-radius: 10px;
  margin-left: 5px;
}

.company-contact-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.22px;
  text-align: left;
  color: #9EA1A8;
}

.line-box {
  width: 95%;
  height: 1px;
  border-bottom: 1px solid #C9D7E3;
  margin-left: auto;
  margin-right: auto;
}

.header-box {
  padding-left: 21px;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.59px;
  color: #0C4568;
}

.reservation-header
{
  font-size: 14px;
  font-weight: 500;
  line-height: 16.59px;
  text-align: left;
  color: #FAB900;
  padding-top: 6px;
  padding-bottom: 8px;
  display: inline-block;
}

.reservation-line-1
{
  //styleName: Body 2;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: #0C4568;
  display: inline-block;
}

.reservation-line-2
{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.22px;
  text-align: left;
  color: #9EA1A8;
  display: inline-block;
  padding-bottom: 10px;
}

.modal-info-text
{
  font-size: 14px;
  font-weight: 500;
  line-height: 16.59px;
  text-align: center;
  color: #9EA1A8;
}

.modal-header-info-text
{
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  text-align: center;
  color: #0C4568;
  padding-bottom: 15px;
  display: inline-block;
}

.rev-company-name
{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.22px;
  text-align: left;
  color: #7C7F85;
  display: block;
  padding-left: 20px;
}

.cal-month
{
  font-size: 18px;
  font-weight: 500;
  line-height: 21.33px;
  text-align: left;
  color: #0C4568;
}

#page-login
{
  @include media-breakpoint-down(sm) {
    margin-top: -75px;
    width: 100%;
    height: 70%;
  }
}

.map-row
{
  margin-top: 80px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: -10px;
  padding-bottom: 0;
}

.deposit-dash-info-details {
  border-radius: var(--spacing-12px, 12px);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(12, 69, 104, 0.11);
  width: 98%;
  margin-left: 0;
  margin-top: 15px;
}

.deposit-box-map {
  margin:0;
  padding: 0;
}

.dash-boxes
{
  margin-right: 5px;
  padding-right: 0;
}

.dash-liner
{
  display: none;
  width: 100%;
  height: 45px;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}