footer {
  opacity: 0;
  transform: translateY(100%);
}

.header {
  opacity: 0;
  transform: translateY(-100%);
  z-index: 1;
  .header-inner > * {
    opacity: 0;
    transform: translateX(-100%);
  }
}
/*.panel, */.contactform {
  opacity: 0;
  transform: translateX(-100%);
}

.menu {
  z-index: 1;
}

.ready {
  /*.panel, */.contactform {
    opacity: 1;
    transform: translateX(0);
    transition: opacity,transform .4s ease-in-out;
    transition-delay: .1s;
    &:nth-child(2) {
      transition-delay: .2s;
    }
    &:nth-of-type(2n + 3) {
      transition-delay: .3s;
    }
  }
  .header {
    transition: opacity,transform .3s ease-in-out;
    opacity: 1;
    transform: translateY(0);
    overflow: hidden;
    .header-inner > * {
      transition: opacity,transform .3s ease-in-out;
      opacity: 1;
      transform: translateX(0);
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i * 0.1 + 0.2}s;
        }
      }
    }
  }
  footer {
    transition: opacity,transform .3s ease-in-out;
    opacity: 1;
    transform: translateY(0);
  }
}


.animate-panel {
  & > * {
    opacity: 0;
    transform: translateX(-100%);
  }
}
.ready {
  .animate-panel > * {
    transition: opacity,transform .3s ease-in-out;
    opacity: 1;
    transform: translateX(0);
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i * 0.1 + 0.2}s;
      }
    }
  }
}

.animate {
  & > * {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i * 0.1}s;
      }
    }
  }
  &--2 > * {
    transition-delay: .1s;
  }
  &--out {
    & > * {
      transition: all .3s ease-in-out;
      transform: translateX(50%);
      opacity: 0;
    }
  }
  &--before-in {
    & > * {
      transform: translateX(-50%);
      opacity: 0;
    }
  }
  &--in {
    & > * {
      transition: all .3s ease-in-out;
      transform: translateX(0);
      opacity: 1;
    }
  }
}


.lds-ring {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 2px;
  border: 2px solid #5c92ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5c92ff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
