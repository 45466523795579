@import "fonts";
@import "variables";
@import "mixins";

@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.css";

@import "~jquery-ui/themes/base/all.css";
@import "~toastr/toastr";

@import "style";
@import "animation";
@import "offline";
@import "extra";
@import "rules";
