body {
  &.white {
    .menu {
      display: none !important;
    }
    .main-container {
      padding-left: 0 !important;
    }
  }
}


[data-page]:not(.page-active) {
  display: none;
}

#page-services {
  [data-rejection-container] {
    .calendar-btn:first-child {
      margin-top: 0 !important;
    }
    img {
      margin-top: 6px;
    }
  }
  .no-hover {
    [data-rejection-container] {
      .calendar-btn {
        background: none;
      }
    }
  }
}

.mini-preloader {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
  }
  &:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    z-index: 11;
    background: url("../images/loading-v2.gif") center center no-repeat;
    background-size: contain;
  }
}
