@mixin position($position, $top: null, $right: null, $bottom: null, $left: null, $default: null) {
  position: $position;
  @if($top) {
    top: $top;
  } @else {
    top: $default;
  };
  @if($right) {
    right: $right;
  } @else {
    right: $default;
  };
  @if($bottom) {
    bottom: $bottom;
  } @else {
    bottom: $default;
  };
  @if($left) {
    left: $left;
  } @else {
    left: $default;
  };
}

@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin absoluteAll($val) {
  @include position(absolute, $default: $val);
}

@mixin relative($args...) {
  @include position(relative, $args...);
}

@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@function rem-calc($size) { $remSize: $size / 16; @return #{$remSize}rem; }

@mixin menuLink() {
  font-size: rem-calc(12);
  text-transform: uppercase;
  color: $color-river-bed-2;
  font-weight: 400;
  transition: all ease-in .3s;
  line-height: 1;
  &:hover {
    color: $color-dodger-blue;
    text-decoration: none;
  }
}

@mixin hiddenCategory($category) {
  .hidden--#{$category} {
    .#{$category} {
      display: none !important;
    }
  }
}
