body {
  &.offline {
    .online-only {
      display: none !important;
    }
  }
  &:not(.offline) {
    .offline-only {
      display: none !important;
    }
  }
}
