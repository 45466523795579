#rulesModal {
  .modal-dialog {
    position: fixed;
    top: 1rem;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    margin: 0;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    padding: 0;
    @include media-breakpoint-up(lg) {
      top: 2rem;
      bottom: 2rem;
      left: 2rem;
      right: 2rem;
      max-width: calc(100vw - 4rem);
      max-height: calc(100vh - 4rem);
    }
    .modal-content {
      max-height: calc(100vh - 2rem);
      @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 4rem);
      }
    }
    .modal-header {
      padding: 1rem;
    }
    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      line-height: 2;
      ul, ol {
        padding-left: 1.5rem;
      }
    }
  }
  .rules-content {

  }
}
