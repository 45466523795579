// Bootstrap
$font-family-sans-serif-2: "Rubik";
$font-family-sans-serif: Rubik;
$font-family-base: Rubik;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;
